import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-rows: 100vh;
  grid-template-columns: repeat(12, 1fr);
`

export const Item1 = styled.div`
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  background: linear-gradient(45deg, #699ed3 30%, black 85%);
  grid-column: 1/-1;
  grid-row: 1/1;
`

export const Item1Text = styled.div`
  grid-row: 6/7;
  grid-column: 4/10;
  display: grid;
  justify-self: center;
  align-self: center;
`

export const Item2 = styled.div`
  margin: 150px 100px 0 100px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 40vh;
  display: grid;
  grid-column: 1/-1;
  grid-gap: 20px;
  @media (max-width: 1300px) {
    margin: 50px 10px 0 10px;
    grid-template-rows: min-content min-content;
  }
`

export const StoryPictures = styled.div`
  display: grid;
  grid-column: 1/8;
  cursor: pointer;
  @media (max-width: 1300px) {
    grid-column: 1/13;
    grid-row: 1/2;
  }
`

export const StoryImageOne = styled.img`
  width: 100%;
  max-height: 400px;

  @media (min-width: 1300px) {
  }
`

export const StoryContent = styled.div`
  display: grid;
  grid-column: 8/-1;
  justify-items: center;
  align-items: center;

  @media (max-width: 1300px) {
    grid-column: 1/-1;
    grid-row: 2/3;
  }
`

export const StoryContentTextOne = styled.p`
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-bottom: -75px;
  @media (max-width: 1300px) {
    margin-bottom: 20px;
  }
`

export const StoryContentTextThree = styled.p`
  margin-top: -75px;
  font-size: 25px;
  @media (max-width: 1300px) {
    margin-top: 0px;
  }
`

export const Item3 = styled.div`
  margin: 150px 100px 100px 100px;
  grid-template-rows: repeat(4, min-content);
  display: grid;
  grid-column: 1/-1;
  justify-items: center;
  grid-gap: 20px;
  @media (max-width: 1300px) {
  }
`

export const Item3Content1 = styled.div`
  grid-row: 1/2;
`
export const Item3Content2 = styled.div`
  grid-row: 2/3;
`
export const Item3Content3 = styled.div`
  grid-row: 3/4;
`
export const Item3Content4 = styled.div`
  grid-row: 4/5;
`

export const ModalPosition = styled.div`
  grid-row: 4/5;
  grid-column: 1/-1;
  grid-row: 1/1;
`

// export const NavContainer = styled.div`
//   width: 100%;
//   grid-column: 1/-1;
// `

// export const NavBar = styled.div`
//   max-width: 1720px;
//   margin: 0 auto;
//   display: grid;
//   grid-template-columns: 1fr auto 1fr;
// `
// export const LeftMenu = styled.div`
//   grid-column: 1;
//   background-color: white;
//   justify-self: end;
// `

// export const MenuItem = styled.a`
//   margin-right: 10px;
// `

// export const Logo = styled.a`
//   background-color: white;
//   grid-column: 2;
//   margin: 0 10px;
// `

// export const RightMenu = styled.div`
//   grid-column: 3;
//   background-color: white;
// `
