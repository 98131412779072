import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ButtonLanding from "./LandingButton"
import { Link as Linking, animateScroll as scroll } from "react-scroll"
import {
  TextOne,
  TextTwo,
  ButtonMargins,
  ButtonsContainer,
  TextContainer,
  TextThree,
} from "./componentStyles/HeaderStyles"
import { Helmet } from "react-helmet"

const Header = ({ siteTitle, contactLink }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{siteTitle}</title>
      </Helmet>
      <TextContainer>
        <TextOne>Robiety</TextOne>
        <TextTwo>Web, Mobile, and Data Application Development </TextTwo>
        <TextThree></TextThree>
      </TextContainer>
      <div style={{ margin: "20px 0" }} />
      <ButtonsContainer>
        <Linking
          activeClass="active"
          to="portfolio"
          spy={true}
          smooth={true}
          offset={-100}
          duration={1500}
        >
          <ButtonLanding text={"Portfolio"} />
        </Linking>
        <ButtonMargins />
        <ButtonLanding
          onClick={() => {
            contactLink()
          }}
          text="Contact"
        />
      </ButtonsContainer>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
