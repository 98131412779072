import React, { useState } from "react"
import { Field, Form } from "react-final-form"
import ButtonLanding from "./LandingButton"

const ArticleForm = props => {
  const [data, setData] = useState()
  const [title, setTitle] = useState("")
  const [date, setDate] = useState("")
  const [summary, setSumary] = useState("")
  const [Opinion, setOpinion] = useState("")
  const [url, setUrl] = useState("")

  const getCardUrl = url => {
    function addhttp(url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "https://" + url
      }
      return url
    }
    return addhttp(url)
  }

  const resp = async (e, url, values) => {
    e.preventDefault()
    if (!url) {
      alert("Please Enter a URL to auto populate fields")
    }

    let info

    url = getCardUrl(url)

    try {
      setSumary(info.data.description)
      setTitle(info.data.title)
      setDate(info.data.date ? info.data.date : values.date)
      setOpinion(values.Opinion)
      setUrl(values.url)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Form
      onSubmit={formObj => {
        props.submit(formObj)
      }}
      initialValues={{
        title: title ? title : "",
        Description: summary ? summary : "",
        date: date ? date : "",
        url: url ? url : "",
        Opinion: Opinion ? Opinion : "",
      }}

      // validate={values => {
      //   const errors = {};
      //   if (!values.url) {
      //     errors.url = "need url";
      //   }

      //   return errors;
      // }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} style={{ height: "100%", width: "100%" }}>
          <div
            style={{ marginBottom: "2.5%", color: "black", fontSize: "25px" }}
          >
            Name
          </div>
          <Field name="name">
            {({ input, meta }) => (
              <input
                style={{ width: "100%", fontSize: "20px" }}
                type="title"
                required
                {...input}
              />
            )}
          </Field>
          <div style={{ margin: "10px" }} />

          <div style={{ margin: "20px" }}> </div>

          <div
            style={{
              margin: "2.5% 0 2.5% 0",
              color: "black",
              fontSize: "25px",
            }}
          >
            Email
          </div>

          <Field name="email">
            {({ input }) => (
              <input
                style={{ width: "100%", fontSize: "20px" }}
                type="email"
                required
                {...input}
              />
            )}
          </Field>

          <div
            style={{
              margin: "2.5% 0 2.5% 0",
              color: "black",
              fontSize: "25px",
            }}
          >
            Message
          </div>
          <Field
            name="description"
            render={({ input, meta }) => (
              <div>
                <textarea
                  placeholder="Tell us about your Application!"
                  style={{ width: "100%", height: "100px", fontSize: "20px" }}
                  {...input}
                  required
                />
              </div>
            )}
          />

          <div style={{ margin: "2.5% 0 0% 0" }} />
          <ButtonLanding type="submit">Submit</ButtonLanding>
        </form>
      )}
    </Form>
  )
}

export default ArticleForm
