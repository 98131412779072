import Modal from "react-modal"
import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { useIntersection } from "react-use"
import { useStaticQuery, graphql } from "gatsby"
import ButtonLanding from "./LandingButton"
import ContactForm from "./ContactForm"
import emailjs from "emailjs-com"
import gsap from "gsap"

import {
  Container,
  Item1,
  Item1Text,
  StoryContent,
  StoryContentTextOne,
  StoryContentTextThree,
  Item2,
  StoryPictures,
  StoryImageOne,
  Item3,
  Item3Content1,
  Item3Content2,
  Item3Content3,
  Item3Content4,
  ModalPosition,
} from "./componentStyles/LandingPageStyle"
// import Image from "../components/image"
import Header from "./header"
import "./layout.css"

const customStylesForArticleForm = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "65%",
    width: "70%",
    boxShadow: "0px 0px 33px 9px black",
  },
}

const Layout = () => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const sectionRef = useRef(null)

  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  })

  const fadeIn = () => {
    gsap.to(sectionRef.current, 1, {
      opacity: 1,
      y: -10,
      ease: "power4.out",
      stagger: {
        amount: 0.3,
      },
    })
  }

  const fadeOut = () => {
    gsap.to(sectionRef.current, 1, {
      opacity: 0,
      y: -20,
      ease: "power4.out",
    })
  }

  intersection && intersection.intersectionRatio < 0.8
    ? //not reached
      fadeOut()
    : fadeIn() //reached

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false)
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const goToSite = newUrl => {
    window.open(`http://www.${newUrl}`, "_blank")
  }

  const sendEmail = data => {
    var template_params = {
      from_name: data.email,
      to_name: "robietyllc@gmail.com",
      message_html: `${data.name}    ${data.description}`,
      sibject: "inquiry",
    }

    var user_id = "user_Tbht44UihbPQzgRLn5aQM"
    var service_id = "default_service"
    var template_id = "template_Y4m2McWk"
    emailjs.send(service_id, template_id, template_params, user_id).then(
      response => {
        console.log("SUCCESS!", response.status, response.text)
        closeModal()
      },
      err => {
        console.log("FAILED...", err)
      }
    )
  }

  return (
    <Container>
      <Item1>
        <Item1Text>
          <Header
            siteTitle={data.site.siteMetadata.title}
            contactLink={openModal}
          />
        </Item1Text>
      </Item1>

      <Item2 ref={sectionRef} id="portfolio">
        <StoryPictures
          onClick={() => {
            goToSite("GazetteMap.com")
          }}
        >
          <StoryImageOne src={require("../images/Graph.png")} />
        </StoryPictures>
        <StoryContent>
          <StoryContentTextOne> Gazette Map</StoryContentTextOne>
          <StoryContentTextThree>
            Gazette Map is a full featured social media application redefining
            the consumption of news media.
          </StoryContentTextThree>
        </StoryContent>
      </Item2>

      <Item3>
        <Item3Content1></Item3Content1>

        <Item3Content2>
          <ButtonLanding onClick={() => openModal()} text="Contact" />
        </Item3Content2>

        <Item3Content3></Item3Content3>

        <Item3Content4></Item3Content4>
      </Item3>
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer>© {new Date().getFullYear()}</footer>
      </div> */}
      <ModalPosition>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStylesForArticleForm}
        >
          <div
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              display: "flexbox",
            }}
          >
            <div>Contact Us</div>
          </div>
          <div style={{ height: "5%" }} />
          <ContactForm submit={sendEmail} />
        </Modal>
      </ModalPosition>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
