import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"

const styles = {
  root: {
    background: "linear-gradient(45deg, #699ed3 30%, #90a4ae 65%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .4)",
  },
}

function ClassNames(props) {
  const { classes, children, text, className, ...other } = props

  return (
    <Button className={clsx(classes.root, className)} {...other}>
      {children || text}
    </Button>
  )
}

ClassNames.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default withStyles(styles)(ClassNames)
