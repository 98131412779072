import styled from "styled-components"

export const TextOne = styled.h4`
  color: white;
  font-size: 55px;
  margin: 0 0 50px;

  @media (max-width: 525px) {
    font-size: 40px;
  }
`

export const TextTwo = styled.p`
  color: white;
  font-size: 30px;
  margin: 0 0 25px;

  @media (max-width: 525px) {
    font-size: 25px;
  }
`

export const TextThree = styled.div`
  color: white;
  font-size: 20px;

  @media (max-width: 525px) {
    font-size: 20px;
  }
`

export const TextContainer = styled.div`
  display: grid;
  animation: fadeInUp 1.5s;
  animation-delay: 0s;
  justify-items: center;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  animation: fadeInUp 2.5s;
  animation-delay: 0s;
  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
      opacity: 0;
    }

    to {
      transform: translate3d(0, 0px, 0);
      opacity: 1;
    }
  }

  @media (max-width: 525px) {
  }
`

export const ButtonMargins = styled.div`
  margin: 0 20px;

  @media (max-width: 525px) {
    margin: 0 10px;
  }
`
